import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter, useRouteError, Outlet, useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { Navbar } from './components';
import { Header } from 'react-frontier';
import { useEffect, useState } from 'react';
import { UserAccess, UserLoginData } from './Classes';
import { TitleProvider } from './Hooks'
import API from './API';

import { 
	AssignOrders,
	Login, 
	ProductList, 
	CategoryList, 
	Orders, 
	OnlineOrders, 
	ProductEdit, 
	CategoryEdit, 
	Users,
	User, 
	OrderView, 
	Zipcodes, 
	Zipcode, 
	SalesReport, 
	Shipments,
	ShipmentMethods,
	ShipmentMethodEdit,
	RfcsView, 
	Rfc, 
	Dashboard, 
	DatesReport, 
	OrderCreator,
	Pdvs,
	Pdv,
	InvoicesReport,
	OrdersReport,
	Variables,
	SitePages,
	PageEdit,
	ProductReport,
	ReportsDashboard,
	TagEdit,
	TagList,
	SalesHourReport,
	SalesWeekdaysReport
} from './screens';

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js'; 

import './style/site.scss'
import 'fomantic-ui-css/semantic.css';
import 'frontier-css/css/frontier.css';



const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

var ErrorElement = (props: { notFound?: boolean })=>{
	const error = (useRouteError() as any);
	return <Header
		text={(error?.status || 404)}
		subtext={(error?.status===404 || props.notFound) ? 'Página no encontrada' : `Hubo un error inesperado. (PBL-LCL-${error?.status})`}
		style={{ fontSize: 100 }}
		subheaderStyle={{ fontSize: 20 }}
		containerStyle={{ marginTop: 15 }}
	/>
}

var SiteContainer = (props: { outlet: JSX.Element })=>{
	var location = useLocation();
	var fullscreen = ['/orders/new', '/orders', '/'];
	var IS_FULLSCREEN = fullscreen.findIndex(a=>location.pathname.toLowerCase()==a.toLowerCase())!=-1;
	var is_report = location.pathname.startsWith('/reports');
	var is_root = location.pathname==='/';
	var login = API.getLogin();

	return <TitleProvider suffix='COCOAN Admin'>
		<Navbar />
		<div style={{ paddingTop: 50, paddingBottom: IS_FULLSCREEN ? 0 : 50 }}>
			{login.access===UserAccess.REPORTES ? (
				is_root ? (
					<ReportsDashboard />
				) : !is_report && login.access===UserAccess.REPORTES ? (
					<ErrorElement notFound />
				) : (
					props.outlet
				)
			) : (
				props.outlet
			)}
		</div>
	</TitleProvider>
}

const Router = createBrowserRouter([{
	path: '/',
	errorElement: <SiteContainer outlet={<ErrorElement />} />,
	element: <SiteContainer outlet={<Outlet />} />,
	children: [
		{ path: '/', element: <Dashboard /> },
		{ path: '/orders', element: <Dashboard /> },
		{ path: '/orders/search', element: <OnlineOrders /> },
		{ path: '/orders/new', element: <OrderCreator /> },
		{ path: '/orders/assign', element: <AssignOrders /> },
		{ path: '/orders/:order_id', element: <OrderView /> },
		{ path: '/order/:order_id', element: <OrderView /> },

		{ path: '/products', element: <ProductList /> },
		{ path: '/products/:product_id*', element: <ProductEdit /> },

		{ path: '/variables*', element: <Variables /> },

		{ path: '/categories', element: <CategoryList /> },
		{ path: '/categories/:category_id*', element: <CategoryEdit /> },

		{ path: '/online/orders', element: <OnlineOrders /> },
		{ path: '/users', element:<Users /> },
		{ path: '/users/:user_id', element: <User /> },
		{ path: '/zipcodes', element: <Zipcodes /> },
		{ path: '/zipcodes/:category_id', element: <Zipcode /> },
		{ path: '/shipments', element: <Shipments /> },
		{ path: '/tags', element: <TagList /> },
		{ path: '/tags/:tag', element: <TagEdit /> },
		
		{ path: '/rfcs', element: <RfcsView /> },
		{ path: '/rfc/:tax_id', element: <Rfc /> },
		
		{ path: '/pdvs', element: <Pdvs /> },
		{ path: '/pdvs/:pdv_id*', element: <Pdv /> },
		{ path: '/pdvs/:pdv_id/cortes/:corte_id*', element: <Pdv /> },
		
		{ path: '/shipments/methods', element: <ShipmentMethods /> },
		{ path: '/shipments/methods/:method_id', element: <ShipmentMethodEdit />},
		
		{ path: '/sites/pages', element: <SitePages />},
		{ path: '/sites/pages/:page_id*', element: <PageEdit />},

		{ path: '/reports/sales' , element: <SalesReport /> },
		{ path: '/reports/dates' , element: <DatesReport /> },
		{ path: '/reports/invoices', element: <InvoicesReport />},
		{ path: '/reports/orders' , element: <OrdersReport /> },
		{ path: '/reports/products' , element: <ProductReport /> },
		{ path: '/reports/hours' , element: <SalesHourReport /> },
		{ path: '/reports/days' , element: <SalesWeekdaysReport /> },
	]
}]);

var Root = ()=>{
	var [login, setLogin] = useState<UserLoginData>(null);
	useEffect(()=>{
		ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
		var ldata = API.getLogin();
		if(ldata) setLogin(ldata);
		var logoutEvent = ()=>{
			var lgn = API.getLogin();
			setLogin(lgn);
		};
		window.addEventListener('user_data', logoutEvent);
		return ()=>{
			window.removeEventListener('user_data', logoutEvent);
		}
	}, []);

	if(!login || login === null){
		return <Login />
	}

	return (
		<>
			<Toaster position="bottom-right" toastOptions={{
				duration: 5000,
				error: {
					iconTheme: {
						primary: 'white',
						secondary: '#ff4b4b'
					},
					style: {
						color: 'white',
						background: '#ff4b4b',
					}
				},
				success: {
					iconTheme: {
						primary: 'white',
						secondary: 'rgb(89, 179, 67)'
					},
					style: {
						color: 'white',
						background: 'rgb(89, 179, 67)'
					}
				}
			}} />
			<RouterProvider router={Router}/>
		</>
	)
}

root.render(<Root/>);