import React, { useEffect, useState } from 'react';
import { Groupper, Button, Input, Field, Message, Header } from 'react-frontier';
import { useTitle } from '../Hooks';
import { Dropdown } from 'semantic-ui-react';
import { bindChange } from '../Util';
import { Chart } from 'react-chartjs-2';
import { SalesChannel } from '../Classes';
import API from '../API';
import moment from 'moment';
import Validator from '../Validator';

interface HourSales{
	hour: number,
	orders: number
}

enum ReportFormat{
	HOURLY = 1,
	HALF_HOURLY = 2
}

var SalesHourReport = ()=>{
	var { setTitle } = useTitle();
	var [startDate, setStartDate] = useState<number>(moment().subtract(7, 'd').unix());
	var [endDate, setEndDate] = useState<number>(moment().unix());
	var [channels, setChannels] = useState<number[]>([]);
	var [type, setType] = useState<number>(null);
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);
	var [report, setReport] = useState<HourSales[]>(null);
	var [loadingReport, setLoadingReport] = useState<boolean>(false);

	useEffect(()=>{
		setTitle('Ventas por hora');
	}, []);

	var showReport = async()=>{
		var { valid, prompts } = Validator({ startDate, endDate, channels, type }, {
			startDate: [{ rule: 'number', label: 'Fecha inicio' }],
			endDate: [{ rule: 'number', label: 'Fecha fin' }],
			channels:  [{ rule: 'minLength', params: [1], label: 'Opciones de datos'}],
			type:  [{ rule: 'empty', label: 'Formato'}]
		});
		setErrorPrompts(prompts);
		if(!valid) return;
		
		var mstart = moment.unix(startDate).format('DD/MM/YYYY');
		var mend = moment.unix(endDate).format('DD/MM/YYYY');

		setLoadingReport(true);
		API.showHoursReport(mstart, mend, channels, type).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			return setReport(res.data);
		}).catch(err=>{
			setErrorPrompts(['Hubo un error inesperado descargando el reporte (LCL-1)']);
		}).finally(()=>{
			setLoadingReport(false);
		})
	}

	return <div>
		<Groupper title='Ventas por hora' width={500} actions={<>
			<Button text='Buscar' color='black' onClick={showReport}/>
		</>}>
			<Field amount={2}>
				<Input label='Fecha de inicio' value={startDate} onChange={setStartDate} calendar={{
					date: startDate,
					mode: 'date', 
					format: 'DD/MM/YYYY'
				}} />
				<Input label='Fecha de fin' value={endDate} onChange={setEndDate} calendar={{
					date: endDate,
					mode: 'date', 
					format: 'DD/MM/YYYY'
				}} />
			</Field>
			<Field label='Tipo'>
				<Dropdown 
					selection 
					value={channels} 
					multiple
					placeholder='Opciones de datos' options={[
						{ value: SalesChannel.PDV, text: 'PDV' },
						{ value: SalesChannel.ONLINE, text: 'Online' },
						{ value: SalesChannel.CALLCENTER, text: 'Callcenter' },
						{ value: SalesChannel.PERSONALIZED, text: 'Personalizados' },
						{ value: SalesChannel.B2B, text: 'Empresariales' },
					]} 
					onChange={bindChange(setChannels, true)}
				/>
			</Field>
			<Field label='Formato'>
				<Dropdown 
					selection 
					value={type}
					placeholder='Opciones de datos' options={[
						{ value: ReportFormat.HOURLY, text: '1 Hora' },
						{ value: ReportFormat.HALF_HOURLY, text: '30 Minutos' },
					]} 
					onChange={bindChange(setType, true)}
				/>
			</Field>
			{report && !report.some(i => i.orders > 0) ? <Message type='error' text='No se econtraron registros relacionados.' /> : null }
			<Message list={errorPrompts} type={'error'} />
		</Groupper>
		{loadingReport ? <Header text={'Cargando datos...'} loading centered /> : report && report.some(i => i.orders > 0) && <Groupper style={{ width: 900, margin: 'auto', marginTop: 15  }}>
			<Chart
				type='bar'
				data={{
					labels: report.map(d => d.hour),
					datasets: [
						{
							label: 'Ventas',
							data: report.map(d => d.orders),
							backgroundColor: '#FFEA0390',
							borderColor: '#DDCB09',
							borderWidth: 1
						},
				],}}
				options={{
				responsive: true,
					plugins: {
						legend: {
							position: 'bottom',
						},
					},
					scales: {
						x: {
							ticks: {
								autoSkip: false,
								maxRotation: 90,
         					minRotation: 90
							},
						},
					}
				}}
			/>
		</Groupper>}
	</div>
}

export default SalesHourReport;