import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Icon, Dropdown } from 'semantic-ui-react';
import { UserLoginData, UserAccess} from '../Classes';
import { bindToggle } from '../Util';
import API from '../API';

interface NavbarItem{
	text: string,
	href?: string,
	items?: {
		text: string,
		href: string,
	}[]
}


interface SidebarMenuProps{
	text: string,
	items: NavbarItem['items']
}

var SidebarMenu = (props: SidebarMenuProps)=>{
	var [shown, setShown] = useState<boolean>(false)

	return <div className="item dropdown">
		<div className="content" onClick={bindToggle(shown, setShown)}>
			<div className="text">{props.text}</div>
			<i className="dropdown icon"></i>
		</div>
		{shown ? (
			<div className="menu">
				{props.items.map(a=>(
					<Link className={`item`} to={a.href} key={`hdrmenu-item-${a.href}`} replace>{a.text}</Link>
				))}
			</div>
		) : null}
	</div>
}

var Navbar = ()=>{
	var [sidebar, setSidebar] = useState<boolean>(false);
	var [user, setUser] = useState<UserLoginData>(null);
	var location = useLocation();

	useEffect(()=>{
		setUser(API.getLogin());
		setSidebar(false);
	}, [location]);

	const NAVBAR_ITEMS : NavbarItem[] = [
		...(user && user.access !== UserAccess.REPORTES ? [
			{ text: 'Ordenes', href: 'orders' },
			{ text: 'Categorías', href: 'categories' },
			{ text: 'Productos', href: 'products' },
			{ text: 'Reportes', items: [
				{ text: 'Ventas por día', href: 'reports/dates' },
				{ text: 'Ventas por hora', href: 'reports/hours' },
				{ text: 'Ventas por dias', href: 'reports/days' },
				{ text: 'Productos por día', href: 'reports/products' },
				{ text: 'Reporte de Productos', href: 'reports/sales' },
				{ text: 'Reporte de Facturas', href: 'reports/invoices' },
				{ text: 'Reporte de Ordenes', href: 'reports/orders' }
			]},
			{ text: 'Mas', items: [
				{ text: 'Puntos de venta', href: 'pdvs'},
				{ text: 'Formas de envío' , href: 'shipments/methods'},
				{ text: 'Usuarios', href: 'users' },
				{ text: 'Códigos postales', href: 'zipcodes' },
				{ text: 'Envios cerrados' , href: 'shipments'},
				{ text: 'Datos fiscales' , href: 'rfcs'},
				{ text: 'Asignar Ordenes', href: 'orders/assign'},
				{ text: 'Config', href: 'variables'},
				{ text: 'Paginas', href: 'sites/pages'},
				{ text: 'Tags', href: 'tags'},
			]},
		] : [
			{ text: 'Reportes', items: [
				{ text: 'Ventas por día', href: 'reports/dates' },
				{ text: 'Ventas por hora', href: 'reports/hours' },
				{ text: 'Ventas por dias', href: 'reports/days' },
				{ text: 'Productos por día', href: 'reports/products' },
				{ text: 'Reporte de Productos', href: 'reports/sales' },
				{ text: 'Reporte de Facturas', href: 'reports/invoices' },
				{ text: 'Reporte de Ordenes', href: 'reports/orders' }
			]},
		])
	]

	return <>
		<div className="ar navbar">
			<div className="left">
				<div className="hamburger item" onClick={()=>setSidebar(!sidebar)}>
					<Icon name={!sidebar ? 'bars' : 'remove'} />
				</div>
				<Link to="/" className="logo item">
					COCOAN
					{/* <img src={`${CDN_ROOT}/assets/logo/LogoH_Small.webp`} alt="" /> */}
				</Link>
			</div>
			<div className="right">
				{NAVBAR_ITEMS.map((a, i)=>(
					a.items ? (
						<Dropdown item text={a.text} className='desktop' key={`navbar-itm-${i}`}>
							<Dropdown.Menu>
								{a.items.map((b, i) => (
									<Dropdown.Item key={`dropdown-itm-${i}`} as={Link} to={b.href}>
										{b.text}
									</Dropdown.Item>
								))}
							</Dropdown.Menu>
						</Dropdown>
					) : (
						<Link className="item desktop" to={`/${a.href}`} key={`navbar-itm-${i}`}>{a.text}</Link>
					)
				))}
				<div className='item desktop' onClick={API.logout}>Logout</div>
				<div className='item mobile'>
					<Icon name='sign out' style={{ margin: 0 }} />
				</div>
			</div>
		</div>
		{sidebar && <>
			<div className="ar navsidebar">
				{NAVBAR_ITEMS.map((a, i)=>{
					if(a.items){
						if(a.items.length==0) return null;
						return <SidebarMenu text={a.text} items={a.items} key={`shdrmenu-drop-item-${i}`} />
					}else{
						var item = a.items && a.items.length==1 ? a.items[0] : a;
						return <Link className='item' to={item.href} key={`shdrmenu-item-${item.href}`} replace={true}>{item.text}</Link>
					}
				})}
			</div>
			<div className="ar closable" onClick={()=>setSidebar(false)}>asd</div>
		</>}
	</>
}

export default Navbar;